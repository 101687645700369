<template>
<div class="encart">
    <h3 v-html="content.titre"></h3>
    <p v-html="content.content"></p>
    <div class="skew-back"></div>
</div>

    
</template>
<script>
export default {
   name: 'Header',
   props: ["content"],

}
</script>