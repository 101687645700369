<template>
<div class="projet-list"> 
    <div class="projet-item" v-for="(item, index) in items" :key="index" >
        <div class="projet-item-info">
            <span class="projet-item-title" v-html="item.title" ></span>
            
            <div class="projet-item-desciption"> 
                <p class="projet-content" v-html="item.content"></p>
                    <span class="projet-agence" v-if="item.agence.nom" >Agence :
                        <a class="projet-link" target="_blank" :href="item.agence.lien" v-html="item.agence.nom"></a> 
                    </span>
                <a class="projet-link" target="_blank" :href="item.lien_projet">Voir le projet</a>
            </div>

        </div>
        <div class="projet-item-thumbnail-wrapper"> 
            <img :srcset="`${item.thumbnail.mobile[0]} 480w,
                            ${item.thumbnail.tablette[0]} 600w,
                            ${item.thumbnail.full} 1024w`"

                sizes="(max-width: 600px) 480px, (max-width: 968px) 600px"

                :src="item.thumbnail.full" loading="lazy"  class="projet-item-thumbnail" >
        </div>
 </div>
 </div>
</template>

<script>


export default {
    components: { },
  name: 'projetListe',
   props: ["items"],
}
</script>

<style>
.projet-list .projet-item{ 
    position: relative;
    display: flex;
    flex-wrap: wrap;
    }
.projet-list .projet-item:nth-child(odd){ flex-direction: row-reverse;}
.projet-item-title{
    font-size: 30px;
    text-transform: uppercase;
    color: #e4e0da;
    font-family: 'hammersmithoneregular', sans-serif;
    text-align: center;
    margin-bottom: 30px;}
.projet-item-info{ 
    position: absolute;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    background: #465054;
    padding: 0 30px;}
a.projet-link{
    position: relative;
    font-weight: bolder;
    text-decoration-line: underline;
    text-align: right;
    margin-top: 20px;}
    a.projet-link::after{
          content: "";
  background-image:  url('../assets/img/iconmonstr-share-11.svg');
    width: 18px;
    height: 18px;
    background-size: contain;
    right: -25px;
    position: absolute;
    top: 3px;
    background-repeat: no-repeat;
overflow: hidden;
background-position-x: -18px;
 transition: background-position 0.1s ease-in-out;
    }
    a.projet-link:hover::after{
        background-position-x: 0;
        transition: background-position 0.1s ease-in-out;
    }
.projet-item-desciption{
    display: flex;
    flex-direction: column;
    padding: 7px 25px;
    color: #e4e0da;}
.projet-item-desciption span{
    width: fit-content;
    font-weight: bolder;
    font-size: 17px;}
.projet-list .projet-item .projet-item-thumbnail-wrapper{
    max-width: 800px;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 90px;}
.projet-list .projet-item:nth-child(odd) .projet-item-thumbnail-wrapper {
   margin-left: 0;
    margin-right: auto;
    margin-bottom: 90px;}
@media screen and (max-width: 1024px) {
  .projet-item-info{  max-width: 300px;  padding: 0 15px;}
}

@media screen and (max-width: 960px) {
    .projet-list .projet-item .projet-item-thumbnail-wrapper{max-width: 70%; margin-top: 100px;}
}
@media screen and (max-width: 600px) {
  .projet-item-info{position: relative;width: 100%;
max-width: 100%;}
	.projet-list .projet-item .projet-item-thumbnail-wrapper,
    .projet-list .projet-item:nth-child(2n+1) .projet-item-thumbnail-wrapper{
    max-width: 100%;
    margin-top: 0;
    margin: auto;
  }
  .projet-list .projet-item{margin-top: 70px;   }

}
</style>