<template v-if="this.home_page"> 
 <div class="layout-wrapper" style="margin-top: 120px;" > 
  <section class="hero-header">
    <div class="section-inner">
      <div class="back-logo" ><img src="../assets/img/background_logo.svg" /></div>
      <h1 class="home-title" >
        <span v-if="this.home_page" 
              v-html="this.home_page.title01"   />

        <span v-if="this.home_page"
              v-html="this.home_page.title02"   />

        <span v-if="this.home_page"
              v-html="this.home_page.title03"   />

        <span v-if="this.home_page"
              v-html="this.home_page.title04"  />

      </h1>
    </div>
  </section>

<section class="main-presentation">

  <div class="section-inner">
    <h2 class="skew-title"  v-if="this.home_page"  v-html="home_page.section_presentation.titre"></h2>
    <div class="prestation">
      <div class="prestation-creation">
        <h3  class="element__" v-if="this.home_page" v-html="home_page.section_presentation.sous_titre_service" ></h3>  
        <div class="content element__" v-if="this.home_page" v-html="home_page.section_presentation.content_service"></div>
      </div>
      <div class="prestation-wordpress">
        <h3  v-if="this.home_page" v-html="home_page.section_presentation.sous_titre_wordpress"></h3>  
         <div class="content"  
            v-if="this.home_page" v-html="home_page.section_presentation.content_wordpress"></div>
      </div>
    </div>

  </div>

  <div class="skew-back"></div>

</section>

<section class="section-service">
  <div class="section-inner">
    <h2 class="skew-title" v-if="this.home_page"  v-html="home_page.section.titre"></h2>
<div class="encart-wrapper">
      <encart v-if="this.home_page" :content="home_page.section.section_01" />
      <encart v-if="this.home_page" :content="home_page.section.section_02" />
      <encart v-if="this.home_page" :content="home_page.section.section_03" />
    </div>
  </div>
 <div class="skew-back"></div>

</section>
<section class="projet-section">
  <div class="section-inner">
    <h2 class="skew-title" v-if="this.home_page" v-html="home_page.section_projet.titre"></h2>

    <p class="projet-text" v-if="this.home_page" v-html="home_page.section_projet.content"></p>
    <projetListe v-if="this.home_page" :items="home_page.section_projet.projet_list" />
   
  </div>
 <div class="skew-back"></div>

</section>
 </div>
</template>
<script>
import Encart from '../components/encart.vue'
import projetListe from '../components/projetList.vue'
import headful from 'headful';

export default {
  components: {  Encart ,projetListe },
  name: 'Home',
  data() {  return { 
    home_page: null,   }  },
  created() {    this.fetchData()  },
  methods: {
    fetchData() {
      /* fetch('https://25et7.fr/dev/2022/25et7/get_page/thue-davy-freelance')*/
      fetch('https://25et7.fr/25et7/get_page/thue-davy-freelance')
          .then(async response => { const data = await response.json();
                if (data) {  
                  this.home_page = data;   
                  headful({
                    title: data.seo.title,
                    description: data.seo.description,  });
              }  
          })
    },

}
}
</script>
<style scoped >
.hero-header h1{
  padding-top: 13%;
  margin: 0;
  display: flex;
  flex-direction:column;
  -webkit-text-stroke: 1px #e4e0da;
  text-stroke: 1px #e4e0da;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  position: relative;
  z-index: 99;}
.hero-header h1 span:nth-child(2){
  -webkit-text-stroke: 0px #e4e0da;
  text-stroke: 0px #e4e0da;
  -webkit-text-fill-color: #e4e0da;
  text-fill-color: #e4e0da;}
.back-logo{
  width: 105%;
  top: -65%;
  left: -33%;
  position: absolute;}
section.main-presentation{
   position: relative;
   margin-bottom: 200px;}
section.main-presentation .section-inner{
  position: relative;
  z-index: 9;
  padding-bottom: 120px;}
section.main-presentation .skew-title{
  color:#3d8294; 
  -webkit-text-stroke: 3px #3d8294;
  text-stroke: 3px #3d8294;
  -webkit-text-fill-color: #3d8294;
  text-fill-color: #3d8294;
  text-transform: uppercase;
  width: 100%;}
section.main-presentation .skew-title >>> span{
  margin-left: 10px;
  -webkit-text-fill-color: transparent;
-webkit-text-stroke: 1px #3d8294; }
.prestation-wordpress {width: 768px; margin-left: auto;}
.prestation-creation{ width: 768px; margin-right: auto;}
section.main-presentation >>> p {
  color:#e4e0da ;
  white-space: break-spaces;}
section.main-presentation >>> ul {
  color:#e4e0da ;
  padding-left: 30px;
  font-size: 17px;}
section.main-presentation >>> li{
margin-bottom: 7px;
  font-weight: bold;
  position: relative;
  display: flex;}
section.main-presentation >>> li::before{
  content: "";
  background-image:  url('../assets/img/plus-circle-thin.svg');
  width: 22px;
  height: 22px;
  background-size: contain;
  left: -30px;
  position: absolute;}
section.main-presentation .skew-back{
  background: #3d8294;
  top: 10.5%;
height: 97.5%;
  transition: top 0.5s ease;}
/** Service  */
section.section-service{
  position: relative;
  margin-top: -125px;}
section.section-service .skew-title{
  text-transform: uppercase;
  -webkit-text-stroke: 3px #e4e0da;
  text-stroke: 3px #e4e0da;
  -webkit-text-fill-color: #e4e0da;}
.encart-wrapper{
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  position: relative; 
  justify-content: space-between; }
.encart{ 
  width:350px;
  margin-bottom: 40px;
  color:#465054;
  padding: 20px;
  position: relative;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; }
.encart >>> h3,
.encart >>> p{
  position: relative;
  z-index: 99;}
.encart >>> .skew-back{
  background: #eb8a00;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;}
.encart:nth-child(2){ margin-top: -81px;}
.encart:nth-child(3){ margin-top: -162px;}
/** Realisation  */
section.projet-section{
  position: relative;
  margin-top: -85px;
  z-index: 9;}
section.projet-section .section-inner{z-index: 9;}
section.projet-section .skew-back{
  background: #465054;
  top: 0;}
section.projet-section .skew-title {
  color: #465054;
  -webkit-text-stroke: 3px #465054;
  text-stroke: 3px #465054;
  -webkit-text-fill-color: #465054;
  text-fill-color: #465054;}
.projet-text{
  display: block;
  padding-top: 30px;
  color: #e4e0da;
  margin-bottom: 30px;}
.projet-text >>> a{
  font-size: 17px;
    position: relative;
    font-weight: bolder;
    text-decoration-line: underline;
    text-align: right;
    margin-top: 20px;}
@media screen and (max-width: 1280px) {
  section.projet-section .skew-back{ top: 10px; }
}
@media screen and (max-width: 1100px) {
.back-logo{ left: -25%;}
  section.main-presentation .skew-title{width: 100%;}
  section.main-presentation .skew-back{ top: 9%;}
  .hero-header h1{ font-size: 54px;}
  .encart{width: calc( 33% - 15px);}
      section.projet-section .skew-back{top: 0;  }
  }
@media screen and (max-width: 1024px) {
.back-logo{top: -55%;left: -20%;}}
@media screen and (max-width: 960px) {
  section.main-presentation .skew-back{height: 95.5%;top: 13%;}
  .back-logo{ transform: translateX(-50%);left: 50%;}
  .hero-header h1{text-align: center;}
   section.projet-section .skew-back{ top: 10px; }
}
@media screen and (max-width:800px) {
  .encart{width:100%;
  margin-bottom: 50px;
  height: 350px;
  margin-top: 0 !important;}
}
@media screen and (max-width: 768px) {
.back-logo{   width: 200%; top: -85%;}
section.main-presentation .skew-back{top: 6%;height: 102%;}
.prestation-wordpress,
.prestation-creation{ width: 100%; margin-right: auto; margin-left: auto;}



}
@media screen and (max-width: 680px) {
section.main-presentation .skew-back { top: 8.5%;height: 99.5%;}
}
@media screen and (max-width: 600px) {
  .projet-item-info{position: relative;}
	.projet-list .projet-item .projet-item-thumbnail-wrapper{
    max-width: auto;
    margin-top: 0;
  }
.back-logo{
  width: 270%;
top: 50%;
transform: translate(-50%, -50%);}
section.main-presentation .skew-back{
  top: 8.5%;
  height: 100%;
}

}
@media screen and (max-width: 600px) { 
section.main-presentation .skew-back{
  top: 8.5%;
  height: 100%;
}
section.projet-section .skew-title{
  width: 100%;
}
section.projet-section .skew-back{
  top: 28px;
}
}

@media screen and (max-width: 530px) {
	section.main-presentation .skew-back{
    top: 10.5%;
  }
}

@media screen and (max-width: 380px) {
	section.main-presentation .skew-back{
    top: 10.5%;height: 98%;
  }
}
</style>
